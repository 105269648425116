.error-alert {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  display: flex;
  padding: 6px 16px;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  box-shadow: none;
}
.error-alert-icon {
  color: #f44336;
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 22px;
  margin-right: 12px;
}
.icon-root {
  font-size: inherit;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
.error-message {
  padding: 8px 0;
}
.text-message {
  text-align: center;
}
