@import "@/styles/variables.module.scss";

.full-header {
  margin: 0;
}
.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.full-header .main-menu-nav {
  background-color: #fff;
  box-shadow: 0 10px 10px -5px rgb(0 0 0 / 23%);
}
.full-header .global-nav {
  text-align: right;
  background-color: $primary-color;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.full-header .global-nav .environment-box {
  margin-right: auto;
  background: aqua;
  margin-left: 50px;
  padding: 4px;
  font-weight: bold;
  margin-bottom: -10px;
  font-size: 15pt;
}

.full-header .global-nav .small-primary-button {
  padding: 0 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 24px 0 0;
}
.button-size-small {
  padding: 0.5em 0.625em;
  margin-bottom: 12.6px;
  border-radius: 4px;
  font-size: 1rem;
  font-size: 18px;
}
.small-primary-button {
  background: $primary-color;
  border: 1px solid $primary-color !important;
}
.button-style {
  display: inline-block;
  margin-bottom: 0;
  color: #fff !important;
  text-decoration: none !important;
  text-align: center;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: transparent;
  overflow: hidden;
}
.button-size-small {
  padding: 0.625em 1.25em;
}
.full-header .main-menu-nav nav .desktop-only {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-header .main-menu-nav nav .desktop-only .logo {
  padding-left: 4.5rem;
  padding-left: 72px;
}
.full-header .main-menu-nav nav .nav-title {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.full-header .main-menu-nav nav .desktop-only .logo {
  padding-left: 72px;
}
.full-header .main-menu-nav nav .nav-title a {
  display: block;
  color: #222;
  font-size: 1.25rem;
  font-size: 20px;
  font-weight: 700;
  padding: 16px 26px;
  text-decoration: none;
  margin-bottom: 0;
}
.logo {
  --RDG-logo: url(../../../public/images/rdg-logo-color.png);
}
.logo img {
  content: var(--RDG-logo);
  display: block;
  max-width: 100%;
  height: 50px;
  padding: 0 60px;
}
.full-header .global-nav .small-primary-button:hover {
  text-shadow: none;
  background: #fff;
  color: #222 !important;
  border: 1px solid transparent !important;
  box-shadow: none;
}
.full-header .main-menu-nav nav .nav-title .header-link:hover {
  color: $secondary-color;
}
.full-header .main-menu-nav nav .nav-title a:focus {
  color: $secondary-color;
  text-decoration: underline;
}
.full-header {
  margin-bottom: 36px;
  line-height: 1.9;
}

.site-banner {
  padding: 1rem 0;
  line-height: 1.5rem;
}

.site-banner-info {
  color: #252525;
  background-color: #91e0eb
}

.site-banner-warning {
  color: #252525;
  background-color: #ffbd00
}

.site-banner header {
  font-family: IBM Plex Sans,sans-serif;
  font-size: clamp(1.44rem,1.3974rem + .213vi,1.5625rem);
  font-weight: 600
}

.site-banner-body {
  flex-wrap: wrap;
  place-content: baseline space-between;
  row-gap: .5rem;
  font-size: clamp(1.2rem,1.1826rem + .087vi,1.25rem);
  display: flex
}

.site-banner-body p:last-of-type,.site-banner-body:last-child {
  margin-bottom: 0
}


.stack-0>* {
  --stack-gap: 0
}

.stack-1>* {
  --stack-gap: .25rem
}

.stack-2>* {
  --stack-gap: .5rem
}

.stack-3>* {
  --stack-gap: 1rem
}

.stack-4>* {
  --stack-gap: 1.5rem
}

.stack-5>* {
  --stack-gap: 3rem
}

.stack-6>* {
  --stack-gap: 5rem
}

.stack>*+* {
  margin-block-start:var(--stack-gap,1.5rem)}

.stack>:last-child {
  margin-bottom:0
}

.stack-divider {
  --divider-color: var(--archive-color,#020ba9);
  --divider-line-height: 4px;
  --stack-gap: 3rem
}

.stack-divider-0 {
  --stack-gap: 0
}

.stack-divider-1 {
  --stack-gap: .25rem
}

.stack-divider-2 {
  --stack-gap: .5rem
}

.stack-divider-3 {
  --stack-gap: 1rem
}

.stack-divider-4 {
  --stack-gap: 1.5rem
}

.stack-divider-5 {
  --stack-gap: 3rem
}

.stack-divider-6 {
  --stack-gap: 5rem
}

.stack-divider-primary {
  --divider-color: #020ba9
}

.stack-divider-secondary {
  --divider-color: #115bfb
}

.stack-divider-success {
  --divider-color: #198630
}

.stack-divider-info {
  --divider-color: #00cadd
}

.stack-divider-warning {
  --divider-color: #ffbd00
}

.stack-divider-danger {
  --divider-color: #cd0000
}

.stack-divider-light {
  --divider-color: #f2f2f2
}

.stack-divider-dark {
  --divider-color: #212529
}

.stack-divider-accent {
  --divider-color: #bedd5f
}

.stack-divider-notice {
  --divider-color: #115bfb
}

.stack-divider>* {
  position: relative
}

.stack-divider>:not(:first-child):before {
  top: calc((var(--stack-gap)/2 + var(--divider-line-height)/2)*-1);
  width: var(--divider-line-width,100%);
  height: var(--divider-line-height);
  content: "";
  background-color: var(--divider-color,#020ba9);
  position: absolute;
  left: 0
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}