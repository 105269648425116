@import "@/styles/variables.module.scss";

.footer {
  background-color: $primary-color;
  padding: 45px;
  position: sticky;
  top: 100vh;
}

.footer .links a {
  display: inline-block;
  color: #fff;
  line-height: 120%;
}
.footer .links {
  margin-top: 35px;
}
.footer .links a:hover {
  color: #fff;
}

.footer .links a:hover,
.footer .links a:focus {
  color: #fff;
  text-decoration: none;
}
.footer-grid {
  max-width: 71.111em;
  margin: 0 auto;
  position: relative;
}
.footer .logos {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  --RDG-dep-logo: url(../../../public/images/rdg-logo.png);
}

.footer .logos .department-logo img {
  content: var(--RDG-dep-logo);
  display: block;
  max-width: 100%;
  height: 80px;
}

.footer .logos {
  display: block;
  width: 100%;
  height: 80px;
}
